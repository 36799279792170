import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggle"];

  toggle() {
    this.toggleTargets.forEach((el) => {
      el.classList.toggle('d-none')
      el.dataset.toggleDisplayOnceTarget = 'toggled'
    })
  }
}
